import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, CampaignService, EventsService, PaymentService, StorageService, UtilService } from '../../services';
import { IPaymentParam } from '../../models/payment-params.model';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-payment-routing',
  templateUrl: './payment-routing.component.html',
  styleUrls: ['./payment-routing.component.scss']
})
export class PaymentRoutingComponent implements OnInit {

  params: IPaymentParam;
  userdata;
  typUrl = '/new/thankyou';

  delete_param_list = ['amt', 'info_1', 'dnr', 'currency', 'payment_v', 'url', 'st'];

  constructor(
    private actRoute: ActivatedRoute,
    private router: Router,
    private paymentService: PaymentService,
    private storageService: StorageService,
    public campaignService: CampaignService,
    private eventsService: EventsService,
    private util: UtilService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getParams();
  }

  /**Get all the params from the url and send them
   * for order status check
   */
  async getParams() {
    this.systemEvents({ event: 'pageView' });
    await this.setParams();
    this.userdata = this.util.getUserData();
    this.checkTheResponse(this.params, this.userdata);
  }

  setParams() {
    return new Promise(async (resolve, reject) => {
      this.params = { ...this.util.removeEmptyFromObject(this.actRoute.snapshot.queryParams) };
      if (this.params['order_id']) {
        this.params['oi'] = this.params['order_id'];
      }
      const sessionParams = this.storageService.getFromSession('payment_return_params');
      if (sessionParams) {
        this.params = { ...this.params, ...sessionParams };
        // this.storageService.deleteFromSession('payment_return_params');
      }
      if (this.util.vars.isVariableLogin) {
        const user = await this.authService.userLoginWithEmail(this.params);
      }
      resolve(true);
    });
  }
  /**Check the order status after payment */
  checkTheResponse(params, userdata) {
    if (params?.ORDERID && params?.ORDERID !== params?.oi) {
      if (params?.or === 'tp') {
        this.router.navigate(['/'], { queryParams: this.util.utm_url_obj });
      } else {
        this.failedRedirection();
      }
      return;
    }
    this.paymentService.getOrderStatusAfterPayment(params).subscribe((res: any) => {

      const cartData = this.util.storage.getFromSession('cartData');
      if (cartData?.showKCart) {
        this.typUrl = '/new/kettokart/thankyou';
      }
      if (params?.plan && params?.cover && params?.cover_amount && params?.person) {
        this.paymentService.addCriticalIllness(userdata?.id, params?.oi, { person: +params?.person, cover: params?.cover + ' lakh', illnessAmount: +params?.cover_amount });
      }
      this.systemEvents({
        event: 'payment_response',
        info_1: res && res.data ? res.data.status : '',
        info_3: JSON.stringify(this.params),
        info_4: res && res.data ? JSON.stringify(res.data) : '',
      });
      if (res.data.status === 'success') {
        if (params.pledge_more && +params?.id !== environment.health_first_campaign_id) {
          const systemEvent = {
            eventName: 'pledge_more_confirmed',
            event_type: 'impact',
            info_1: 'position',
            info_2: `1,${userdata?.listsubscriptions[params.sip_cause || 'medical']?.id},${params.amt}`,
            info_3: 'impact',
            info_4: params?.sip_cause
          };
          this.eventsService.sendSystemEvent(systemEvent).subscribe(_ => _);
        }
        let qparams;
        if (params.payment_v === 'v2' && params.k_params) {
          qparams = JSON.parse(params.k_params);
          if (!(params?.st === 'f' && params?.tip_amount && +params?.tip_amount !== 0)) {
            delete qparams?.last_success_campaign_id;
          }
          this.delete_param_list.forEach(val => {
            delete qparams[val];
          });
        } else {
          qparams = {
            id: params.id, oi: params.oi, or: params.or, social: params.social,
            payops: params.payops, utm_source: params.utm_source, eventPage: params.eventPage, dnr: params.dnr,
            tus: params.tus, cause: params.cause, lpm: params.lpm, offer: params.offer, sip_story: params.sip_story,
            ministoryquiz: params.ministoryquiz,
            person: params?.person,
            cover: params?.cover,
            cover_amount: params?.cover_amount,
            hfmini: params?.hfmini || null,
            hfmental: params?.hfmental || null,
            coupon_code: params?.coupon_code || null,
            sub_campaign_id: params?.sub_campaign_id || null,
            frtip: params.frtip, coupon: params?.coupon, vendor: params?.vendor, plan: params?.plan, pledge_more: params?.pledge_more || null, ...this.util.utm_url_obj
          };
        }
        qparams.entity_type = this.params?.entity_type || null;
        this.router.navigate([this.typUrl], { queryParams: qparams });

        this.storageService.deleteFromSession(params.oi);
        this.storageService.delete('abandonedCartOtd');

      } else if (res.data.status === 'failed') {
        this.failedRedirection();
      }
    }, (error) => {
      this.systemEvents({
        event: 'payment_response',
        info_1: 'failed',
        info_3: JSON.stringify(this.params),
        info_4: error && error.error ? error.error.message : '',
      });
      this.params.reason = error?.error?.message;
      this.failedRedirection();
    });
  }
  /**Failed transaction redirection
   * where `st` is stand for status of the transaction like
   * successes(s) or failed(f)
   * Origins like
   * Fundraiser=f, Story=s, Thank You Page=tp, Portfolio Page=ip
   */
  failedRedirection() {

    if (this.params.payment_v === 'v2' && this.params.k_params) {
      const qparam = {
        st: 'f',
        ...JSON.parse(this.params.k_params)
      };
      if (this.params.or === 'tp' || this.params.or === 'rfd') {
        qparam['last_success_order_id'] = this.userdata?.lastorder?.order_id || null;
        qparam['last_success_campaign_id'] = this.userdata?.lastorder?.campaign_id || null;
        qparam['pre_sip_cause'] = qparam?.sip_cause || null;
        qparam['sip_cause'] = null;
      }
      if (this.params.u_or) {
        qparam['or'] = this.params.u_or;
      }
      if (this.params.or !== 'tp') {
        qparam['ct'] = null;
      }
      if (this.params?.sip_wallet) {
        qparam['amt'] = +this.params.amt / (+this.params.wallet_tenure || 6);
      }

      this.router.navigate([this.params.url], { queryParams: qparam });
      return;
    }

    switch (this.params.or) {
      case 'f':
        this.router.navigate(['/fundraiser', this.params.ct], {
          queryParams: {
            st: 'f', oi: this.params.oi, id: this.params.id, social: this.params.social, payops: this.params.payops, min_donation: this.params?.min_donation || null, hfmini: this.params?.hfmini || null, hfmental: this.params?.hfmental || null,
            multi_patient_opted: this.params?.multi_patient_opted || null,
            ...this.util.utm_url_obj
          }
        });
        break;
      case 's':
        this.router.navigate(['/stories', this.params.ct], {
          queryParams: {
            st: 'f', oi: this.params.oi, id: this.params.id, social: this.params.social, payops: this.params.payops, min_donation: this.params?.min_donation || null, hfmini: this.params?.hfmini || null, hfmental: this.params?.hfmental || null, mini_story: this.params?.mini_story || null,
            multi_patient_opted: this.params?.multi_patient_opted || null,
            ...this.util.utm_url_obj
          }
        });
        break;
      case 'tp':
        this.router.navigate(['/new/thankyou', this.params.ct], {
          queryParams: {
            st: 'f', oi: this.params.oi, id: this.params.id, or: this.params.typOr, social: this.params.social, payops: this.params.payops, last_success_campaign_id: this.userdata?.lastorder?.campaign_id || null,
            last_success_order_id: this.userdata && this.userdata.lastorder ? this.userdata.lastorder.order_id : null, tip_amount: this.params?.tip_amount || null, frtip: this.params?.frtip,
            hfmini: this.params?.hfmini || null,
            multi_patient_opted: this.params?.multi_patient_opted || null,
            hfmental: this.params?.hfmental || null,
            entity_type : this.params?.entity_type || null,
            ...this.util.utm_url_obj
          }
        });
        break;
      case 'up':
        this.router.navigate(['/new/campaign/campaign_update'], {
          queryParams: {
            st: 'f', oi: this.params.oi, id: this.params.id, or: 'up', fmd_id: this.params.fmd_id, update_id: this.params.update_id, type: 'onetime',
            action_date: this.params.action_date, donated_amount: this.params.donated_amount,
            donated_currency: this.params.donated_currency, is_donor: this.params.is_donor,
            hfmini: this.params?.hfmini || null,
            multi_patient_opted: this.params?.multi_patient_opted || null,
            hfmental: this.params?.hfmental || null,
            ...this.util.utm_url_obj
          }
        });
        break;
      case 'tann':
        this.router.navigate(['/new/anniversary'], {
          queryParams: { st: 'f', ...this.util.utm_url_obj }
        });
        break;
      case 'tip':
        this.router.navigate(['/new/standalonetip'], {
          queryParams: { st: 'f', ...this.util.utm_url_obj }
        });
        break;

      case 'ps':
        this.router.navigate(['/new/payment'], {
          queryParams: {
            ct: this.params.ct,
            id: this.params.id,
            currency: this.params.pcurrency,
            st: 'f',
            type: 'full',
            vendor: this.params.vendor || null,
            plan: this.params.plan || null,
            person: this.params?.person || null,
            cover: this.params?.cover || null,
            pledge_more: this.params.pledge_more || null,
            hfmini: this.params?.hfmini || null,
            multi_patient_opted: this.params?.multi_patient_opted || null,
            hfmental: this.params?.hfmental || null,
            coupon_code: this.params?.coupon_code || null,
            ...this.util.utm_url_obj
          }
        });
        break;

      default:
        this.router.navigate(['/payment-failed'], {
          queryParams: {
            oi: this.params.oi,
            id: this.params.id,
            reason: this.params.reason,
            ...this.util.utm_url_obj
          }
        });
        break;

    }
  }

  systemEvents(data) {
    const pageEvent = {
      eventName: data.event,
      event_type: 'payment',
      // event_type_id: this.actRoute.snapshot.queryParams['oi'],
      page_name: 'payment-redirect-page',
      info_1: data.info_1 || '',
      info_2: this.actRoute.snapshot.queryParams['oi'],
      info_3: data.info_3 || '',
      info_4: data.info_4 || '',
    };
    this.eventsService.sendSystemEvent(pageEvent).subscribe(_ => _);
  }

}
